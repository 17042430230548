import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

import { Helper } from '../local/helper';
import { IAService } from '../local/ia.service';
import { HttpClientService } from './../http-client.service';

@Injectable({
  providedIn: 'root'
})
export class ArticlesService {

  constructor(
    public http: HttpClientService,
    public IA: IAService
  ) { }

  async list(filters: {
    limit: number,
    page: number,
    showDesc?: boolean,
    shortDesc?: number,
    order?: string,
    filters?: {
        category: any,
        search : string
    }
  }) {


    let url = `${ environment.apiHost }articles`;

    return this.http.post(url, filters)
      .then((result: any) => {
            if (!(result)) {
                  return {

                  };
            }
            setTimeout(() => {
                  this.IA.dismissLoader();
            }, 500);
            return result;
      })
      .catch((err) => {
            setTimeout(() => {
                  this.IA.dismissLoader();
            }, 500);
            return [];
      });

  }

  async details(id: number) {
    let url = `${ environment.apiHost }articles/${id}`;

    return this.http.post(url, {
      images: true,
      video: true,
      links: true,
      related: true,
      categories: true,
    })
    .then((result: any) => {
      if (!(result)) {
            return { };
      }
      setTimeout(() => {
            this.IA.dismissLoader();
      }, 500);
      return result;
    })
    .catch((err) => {
          setTimeout(() => {
                this.IA.dismissLoader();
          }, 500);
          return [];
    });
  }

  async media(id) {
      let url = `${ environment.apiHost }articles/${id}/media`;

      return this.http.post(url, {
        images: true,
        video: true,
        links: true,
      })
      .then((result: any) => {
        if (!(result)) {
              return { };
        }
        setTimeout(() => {
              this.IA.dismissLoader();
        }, 500);
        return result;
      })
      .catch((err) => {
            setTimeout(() => {
                  this.IA.dismissLoader();
            }, 500);
            return [];
      }); 
  }

  async categories() {
      let url = `${ environment.apiHost }categories`;

      return this.http.get(url)
      .then((result: any) => {
        if (!(result)) {
              return { };
        }
        setTimeout(() => {
              this.IA.dismissLoader();
        }, 500);
        return result;
      })
      .catch((err) => {
            setTimeout(() => {
                  this.IA.dismissLoader();
            }, 500);
            return [];
      });
  }
}
