import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'substr'
})
export class SubstrPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {
    let result = value;
    let position: any = 0, 
    length: any = 1000;

    if(args && args.length ) {
      if(args[1]) {
        length = args[1];
        position = args[0];
      } else {
        length = args[0];
      }
      if(value.length > (length+2)) {
        result = `${ value.substr(position, length) }...`;
      }
    }
    
    return result;
  }

}
